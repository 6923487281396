import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../services/firebase';
import UserAuth from '../screens/auth/UserAuth';
import { Redirect } from '@reach/router';

const IndexPage = () => {
  const [user, loading, error] = useAuthState(getFirebaseAuth());

  if (loading) {
    return <></>;
  }

  return user ? <UserAuth /> : <Redirect noThrow to="/" />;
};

export default IndexPage;
